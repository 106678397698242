#Slider-Home {
	.slick-dots {
		margin: 0;
		bottom: 2rem;
		margin-left: -15%;
	}
}

#testimonials-slide {
	.testimonials-text {
		margin-top: 0;
		text-align: center;
		h3 {
			font-size: 1.5rem;
			color: $white;
			width: 100%;
			text-transform: capitalize;
			margin-bottom: 0.5rem;
		}

		p {
			text-align: center;
			color: $white;
			font-size: 0.875rem;
		}

		a {
			padding: 0.5rem;
			margin: 0;
			&:hover {
				color: $blue;
				background-color: rgba(255,255,255,0.5);				
			}
		}

	}

	.slick-dots {
		top: 0; 
	}

	@include breakpoint(medium large) {
		.testimonials-text {
			margin-top: 4rem;
			h3 {
				font-size: 3.5rem;
				margin-bottom: 1rem;
			}

			a {
				padding: 1rem;
				margin: 1.5rem 0;
			}
		}
	}
}

#business-slide {
	background-color: $gray-light; 
	padding: 3.5rem 2.5rem  1.3rem 2.5rem;
	img {
		display: inline-block;
		margin-right: 3rem;
	} 

	.slick-dots {
		position: relative;
		bottom: 0;
		margin-top: 2rem;
		li {
			
			button {
				border: 1px solid $gray-medium;
			}

			&.slick-active  {
            	color: $gray-medium;
            	background-color: rgba(128,128,128,0.5);
        	}
		}
	}
}

#teambuilding-slide, #dinearound-slide, #events-slide, #amenities-slide, #transportation-slide {
	.slick-dots {
		bottom: 1rem;
	}
	
}

.slidershadow {
	position: absolute;
	width: 100%;
	height: 398px;
	left: 0;
	top: 0;
	z-index: 99;
	background: url(/images/slider/shadow.png) repeat-x top center;
}

#sliderCont {
	position: relative;
	fill:$white;
}

#curvahome {
	position: absolute;
    bottom: 0px;
    max-height: 150px;
    width: 100%;
}

.curvatura {
	position: absolute;
	bottom: -1px;
	max-height: 282px;
	width: 100%;
}

