.planner-form {
	label {
		font-size: 1.1rem;
		font-family: $font-source;
		color: $gray-fonts;
		font-weight: 600;
		text-transform: none;
		&:after {
			content: ':';
		}
	}

	input {
		margin: 0;
		height: 2rem;
	}

	// button {
	// 	background-color: $orange;
	// 	padding: 8px;
		
	// 	color: white;
	// }
}