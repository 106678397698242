/*
|--------------------------------------------------------------------------
| General Styles
|--------------------------------------------------------------------------
*/
html {
	font-size: 16px;
}
body {
	position: relative;
	font-family: $font-source;
	font-style: normal;
	font-size: 16px; 
	font-weight: 300;
	line-height: 1.2;
	width: 100%;
	height: 100%;
	background-color: white;
}

p {
	text-align: justify;
	color: $gray-medium;
	line-height: 1.2;
	font-family: $font-poppins;
	font-size: 14px;
}

a {
	font-family: $font-montse;
	font-weight: 400;
	font-size: 0.75rem;
	text-decoration: none;
	display: block;
}

h1, h2, h3 {
	color: $gray;
	font-size: 1.5625rem;
	font-weight: 400;
	font-family: $font-montse;
}

h1 {
	font-size: 2rem;
}

h2 { 
	font-size: 1.5625rem;
}

h1.orange {
	color: $orange;
}

.slick-dots {
	z-index: 100;
	height: 20px; 
	display: none;
	margin-bottom: 20px;
	li {
		margin-left: 1rem;
		border-radius: 10px;
		button {
			border: 1px solid $white;
			border-radius: 10px;
		}

		button:before {
			content: ' ';
    		color: transparent;
    		border: none;
    		font-family: 'FontAwesome';
    	}

		&.slick-active  {
			font-size: 1rem;
        	color: $white;
        	background-color: rgba(255,255,255,0.5);
    	}
	}
}

.gutter-sizer {
	width: 0;
}

.grid-item { 
	width: 25%; 
	margin: 0; 
	img {
		width: 100%;
	}
}