header {
	    position: absolute;
    	width: 100%;
    	background-color: transparent;
    	top: 0;
    	z-index: 100;
	.img-logo {
		text-align: center;
	}

	a, p {
		color: $white;
		display: inline-block;
		margin: 0;
	}

	p {
		font-size: 10px;
		font-family: $font-montse;
	}

	a {
		font-size: 0.875rem;
		margin-left: 5px;
		font-family: $font-montse;
		&:hover {
			color: $orange;
		}
	}  

	a.language {
		margin: 0;
		&:hover { 
			color: $gray-light;
		}
	}

	ul.social {
		margin-right: 1rem;		
	}

	span.separator {
		display: inline-block;
		margin: 0 0.4rem; 
		color: $orange;
		font-size: 1rem; 
		font-weight: 600;
	}

	.top-header {
		padding: 1.625rem 0 0 0;
	}

	@media (max-width: 640px) {
		.top-header {	
			p {
				display: block;
				text-align: center;
			}
		}

		ul.social {
			display: block;
			margin: 0;
			float: none;
			text-align: center;
			li {
				text-align: center;
			}
		}

		.img-logo {
			text-align: center;
			width: 100%;
			img {
				max-width: 50%;
			}
		}
	}
	nav {
		ul#theMenu.menu {
			list-style: none;
			position: relative;
			table-layout: auto !important;
			margin-top: 2rem;
			li {	
				transition: all 0.5;	
				text-align: center;		
				display: block;
				a {
					padding: 0;
					&:hover {
						color: $white;
					}
				}

				@include breakpoint(medium large) {
					display: table-cell;
					ul {
						li {
							display: block;
						}
					}
				}

			}

			li.dropdown {
				position: relative;
				padding-bottom: 0;
				&:after {
					content: '\f0d7';
					font-family: 'FontAwesome';
					color: $white;
					position: absolute;
					display: block;
					width: 100%;
				}

				ul.dropdown-menu { 
					min-width: 180px;
					display: none;
					list-style: none;
					margin: 25px 0 0 0;
					border-bottom: 4px solid $blue;
					li {				
						a {
							padding: 1rem 2rem 0.375rem 0.875rem;
							width: 100%;
							color: $white;
							font-family: $font-source;
							font-weight: 300;
							font-size: 0.875rem;
							text-transform: capitalize;
							transition: all 0.5s;
						}

					}
				}

				&:hover {
					&:after {
						color: $orange;
					}

					ul.dropdown-menu {
						display: block;
					}
				}
			}
		}
	}

	div#DesktopMenu {
		display: none;
		background-color: $orange;
		ul#theMenu {
			li {
				a {
					padding: 10px;
				}
			}

			li.dropdown {
				ul.dropdown-menu {
					position: relative;
					li {
						background-color: rgba(255,255,255,0.5);
						border-bottom: 2px solid $gray;	
						text-align: center;					
						a {
							color: $gray;
						}

						&:hover {
							background-color: rgba(255,255,255,0.75);
							transition: all 0.5s;
							a {
								color: $orange;
							}
						}
					}
				}
				&:after {
					display: inline-block;
				}
			}
		}
		@include breakpoint(medium large) {
			display: block;
			background-color: rgba(255,255,255, 0);
			ul#theMenu > li > a {
				padding: 0;
			}



			ul#theMenu > li.dropdown {
				&:after {
					display: block;
				}
				ul.dropdown-menu {
					position: absolute;
					li {
						background-color: rgba(141,143,146,0.75);
						border-bottom: 2px solid $gray;
						text-align: left;						
						a {
							color: $white;
						}

						&:hover {
							background-color: rgba(128,128,128,0.75);
							transition: all 0.5s;
							a {
								color: $white;
							}
						}

						&:after {
							display: block;
						}
					}
				}
			}
		}
	}

	#Hamburguer {
		cursor: pointer;
		text-align: center;
		color: $orange;
		@include breakpoint (medium large) {
			display: none;
		}
	}

}